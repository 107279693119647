export type WhoamiType = AnonymousWhoamiType | AuthenticatedWhoamiType


export interface AnonymousWhoamiType {
    is_authenticated: false
    id: undefined
}

interface AuthenticatedWhoamiType extends ReadonlyUserType, WalletType {
    is_authenticated: true
    is_staff?: boolean
}

interface userSettings {
    comp_active: boolean
    sports_cross_allowed: boolean | null
}
export interface ReadonlyUserType {
    site?: string
    id: number
    username: string
    nickname: string
    icon: string
    bank_name?: string
    bank_number?: string
    bank_owner?: string
    settings: userSettings
}

export type PermissionsType = string[]

export interface WalletType {
    money: number
    point: number
    comp: number
}

export interface UserType extends ReadonlyUserType {
    site?: string
    parent: ReadonlyUserType | undefined
    is_agent: boolean
    phone: string | undefined
    bank_name: string | undefined
    bank_number: string | undefined
    bank_owner: string | undefined
    bank_password: string | undefined
    slot_rolling_ratio: number
    casino_rolling_ratio: number
    slot_void_min_interval: number
    casino_void_min_interval: number
    memo: string | undefined
    money: number
    point: number
    comp: number
    deposit_account: number | undefined
}

export interface UserNodeType extends UserType {
    child_nodes: UserNodeType[] | undefined
}

export interface UserTreeType {
    site: string
    root_nodes: UserNodeType[]
}


export const BANK_CHOICES = [
    ["002", "산업"],
    ["003", "기업"],
    ["004", "국민"],
    ["007", "수협"],
    ["011", "농협"],
    ["020", "우리"],
    ["023", "SC제일"],
    ["027", "한국씨티"],
    ["031", "대구"],
    ["032", "부산"],
    ["034", "광주"],
    ["035", "제주"],
    ["037", "전북"],
    ["039", "경남"],
    ["045", "새마을금고"],
    ["048", "신협"],
    ["064", "산림조합"],
    ["071", "우체국"],
    ["081", "하나은행"],
    ["088", "신한"],
    ["089", "케이뱅크"],
    ["090", "카카오뱅크"],
    ["092", "토스뱅크"],
    ["999", "기타"],
]

export function displayBankName(bankCode: string) {
    return BANK_CHOICES.find(([code, label]) => code === bankCode)?.[1]
}